@use '../util' as *;

h1,
h2,
h3 {
	margin-top: 0;
	line-height: 1.1;
}

h1 {
	font-size: rem(36);
	letter-spacing: rem(1);
}

a,
a:visited,
a:active {
	text-decoration: none;
}

h4 {
	font-size: rem(20);
}

h5{
	font-size: rem(11);
}