@use '../util' as *;  


html {
	font-size: 100%;
	font-family: var(--font-body);
	box-sizing: border-box;
}

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

body {
	position:absolute;
    left: 50%;
    transform: translate(-50%, 0%) ;
	margin: 0;
	padding: 1rem 2rem;
	background-color: #ffffff;// #f6f7f8;
	color:hsl(240, 3%, 7%);
	/*center everything*/
	display: flex; 
	flex-direction: column;
	align-items: center;
	text-transform: uppercase;
	font-weight: 600;
	font-size: rem(36);
}

p{
	font-size: .8rem;
	font-weight: 100;
    line-height: 1rem;
	text-transform: none;
}


