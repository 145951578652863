:root {
  --font-body: "Open Sans",Arial, sans-serif;
}

:root {
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
}

@keyframes flip-in {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(-90deg);
  }
}
@keyframes flip-out {
  from {
    transform: rotateX(-90deg);
  }
  to {
    transform: rotateX(0deg);
  }
}
.flip-in {
  animation: flip-in 250ms;
  animation-fill-mode: forwards;
}

.flip-out {
  animation: flip-out 250ms;
  animation-fill-mode: forwards;
}

@keyframes jump {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-6px);
  }
  100% {
    transform: translateY(0);
  }
}
.jump {
  animation: jump 250ms;
  animation-fill-mode: forwards;
}

@keyframes shake-in {
  0% {
    transform: translate3d(0, 0, 0);
  }
  33% {
    transform: translate3d(-4px, 0, 0);
  }
  66% {
    transform: translate3d(4px, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.jump {
  animation: jump 250ms;
  animation-fill-mode: forwards;
}

.shake-in {
  animation: shake-in 250ms;
  animation-fill-mode: forwards;
}

html {
  font-size: 100%;
  font-family: var(--font-body);
  box-sizing: border-box;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  margin: 0;
  padding: 1rem 2rem;
  background-color: #ffffff;
  color: #111112;
  /*center everything*/
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 2.25rem;
}

p {
  font-size: 0.8rem;
  font-weight: 100;
  line-height: 1rem;
  text-transform: none;
}

h1,
h2,
h3 {
  margin-top: 0;
  line-height: 1.1;
}

h1 {
  font-size: 2.25rem;
  letter-spacing: 0.0625rem;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 0.6875rem;
}

.container {
  padding: 0;
  max-width: 69.375rem;
  margin: 0 auto;
  position: absolute;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (min-width: 71.875em) {
  .container {
    padding: 0;
    transform: translate(0, 0);
  }
}

.remove-all-margin-padding {
  margin: 0 !important;
  padding: 0 !important;
}

.modal {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}

.modal-content {
  position: absolute;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1rem 1.5rem;
  width: 24rem;
  border-radius: 0.5rem;
}

.close-button {
  float: right;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: lightgray;
}

.close-button:hover {
  background-color: darkgray;
}

.show-modal {
  opacity: 0.9;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.trigger {
  width: 60px;
  height: 30;
}

.trigger:hover {
  background-color: lightgray;
}

.copyClipboard {
  width: 60px;
  height: 30;
}

.copyClipboard:hover {
  background-color: lightgray;
}

.navbar {
  position: relative;
  background: white;
}

#navlist {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  background: white;
}

#navlist li {
  flex: auto;
  text-align: center;
  padding: 1.6em;
  font-size: 18px;
  color: black;
}

#footerList {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  background: white;
}

#footerList li {
  flex: auto;
  text-align: left;
  padding: 0;
  font-size: 14px;
  color: white;
}

#footerList li a {
  color: lightgray;
}

.guess {
  display: grid;
  /*border: hsl(0, 0%, 100%);*/
  grid-template-columns: repeat(5, 1fr);
  gap: 0.1875rem;
  width: 18.75rem;
  margin-bottom: 0.5rem;
}
.guess__tile {
  border: 0.125rem solid #d3d6da;
  width: 3.25rem;
  height: 3.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
}
.guess .has-letter {
  border-color: #666666;
}
.guess .correct {
  background-color: #6aaa64;
  border-color: #6aaa64;
  color: white;
}
.guess .present {
  background-color: #c9b458;
  border-color: #c9b458;
  color: white;
}
.guess .absent {
  background-color: #86888a;
  border-color: #86888a;
  color: white;
}

.alert {
  font-size: medium;
  text-align: center;
}