

@keyframes flip-in{
    from {
        transform: rotateX(0deg);
    }
    to {
        transform: rotateX(-90deg);
    }
    
}

@keyframes flip-out{
    from {
        transform: rotateX(-90deg);
    }
    to {
        transform: rotateX(0deg);
    }
    
}




.flip-in {
    animation: flip-in 250ms;
    animation-fill-mode: forwards;
}

.flip-out {
    animation: flip-out 250ms;
    animation-fill-mode: forwards;
}

@keyframes jump {
    0% {
        transform: translateY(0);
    }    
    50% {
        transform: translateY(-6px);
    }

    100% {
        transform: translateY(0);
    }
}

.jump {
    animation: jump 250ms;
    animation-fill-mode: forwards;  // to keep the last frame
}

@keyframes shake-in {
    0% {
        transform: translate3d(0, 0, 0);
    }    
    33% {
        transform: translate3d(-4px, 0, 0);
    }

    66% {
        transform: translate3d(4px, 0, 0);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}

.jump {
    animation: jump 250ms;
    animation-fill-mode: forwards;  // to keep the last frame
}


.shake-in {
    animation: shake-in 250ms;
    animation-fill-mode: forwards;
}